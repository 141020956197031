import { FC, useEffect, useRef, useState } from 'react';

import {
  Box,
  Heading,
  Link,
  UnorderedList,
  ListItem,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDynamicBreaks } from '../hooks/useDynamicBreaks';
import { useStoreData } from '../hooks/useStoreData';
import { CategoriesModel } from '../types/storeData';

const ALL_CATEGORY = {
  name: 'Show All',
  position: 0,
  slug: 'all',
  image: '',
};

export const CategoryBar: FC = () => {
  const { categories } = useStoreData();
  const containerRef = useRef<HTMLUListElement>(null);
  const breakIndex = useDynamicBreaks(containerRef);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>(ALL_CATEGORY.name);

  const [allSortedCategories, setAllSortedCategories] = useState<CategoriesModel>([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const allSortedCategories = [ALL_CATEGORY, ...categories].sort((a, b) => a.position - b.position);
    setAllSortedCategories(allSortedCategories);
  }, [categories]);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    event.preventDefault();
    const linkText = (event.target as HTMLElement).textContent ?? '';

    setSelectedCategory(linkText);
  };

  return (
    <Box textAlign="left">
      <Heading fontSize="4xl" py={6} data-testid="selected-category">
        {selectedCategory}
      </Heading>

      <Box as="nav" display="flex" alignItems="flex-start">
        <UnorderedList ref={containerRef} listStyleType="none" m={0} p={0} pt={2}>
          {allSortedCategories.map(category => (
            <ListItem key={category.slug} overflow="hidden" display="inline-block" mr={10}>
              <Link
                variant="category-bar"
                fontWeight="semibold"
                onClick={handleClick}
                display="inline-block"
                pb="3px"
                borderBottom="4px"
                borderColor={category.name === selectedCategory ? 'navbar' : 'transparent'}
              >
                {category.name}
              </Link>
            </ListItem>
          ))}
        </UnorderedList>
        {breakIndex > 0 && (
          <Menu isOpen={isOpen} onClose={() => setIsOpen(false)} closeOnSelect={false}>
            <MenuButton
              as={Button}
              variant="unstyled"
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronDown}
                  rotation={isOpen ? 180 : undefined}
                  style={{ transition: 'transform 0.2s' }}
                />
              }
              onClick={toggleDropdown}
              minWidth="min-content"
            >
              See more
            </MenuButton>
            <MenuList>
              {allSortedCategories.slice(breakIndex).map(category => (
                <MenuItem key={category.slug} onClick={handleClick}>
                  <Link variant="category-bar" color={category.name === selectedCategory ? 'navbar' : 'inherit'}>
                    {category.name}
                  </Link>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </Box>
    </Box>
  );
};
