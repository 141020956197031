// TODO: remove this
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, createContext, FC } from 'react';

import { useSearchParams, useNavigate } from 'react-router-dom';

import { useOptionalStoreData } from '../hooks/useStoreData';
import { validateToken } from '../utils/validateToken';

export interface HandshakeDataContextType {
  isHandshakeEnabled: boolean;
  isAuthorised: boolean;
  isCountdownEnabled: boolean;
  userData: Partial<{
    firstName: string;
    lastName: string;
    email: string;
  }>;
  returnUrl: string | null;
  expireAt: number | null;
  expireInSeconds: number | null;
  disableCountdown: () => void;
}

export const HandshakeDataContext = createContext<HandshakeDataContextType>({} as HandshakeDataContextType);

interface StoreDataContextProviderProps {
  children: React.ReactNode;
}

export const HandshakeDataContextProvider: FC<StoreDataContextProviderProps> = ({ children }) => {
  const [isCountdownEnabled, setIsCountdownEnabled] = useState<boolean>(true);
  const storeData = useOptionalStoreData();

  const defaultHandshakeData = {
    userData: {},
    isAuthorised: false,
    isHandshakeEnabled: false,
    isCountdownEnabled,
    returnUrl: null,
    expireAt: null,
    expireInSeconds: null,
    disableCountdown: () => setIsCountdownEnabled(false),
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [handshakeData, setHandshakeData] = useState<HandshakeDataContextType>(defaultHandshakeData);
  const navigate = useNavigate();

  useEffect(() => {
    if (!storeData) return;

    const {
      store: { handshaking },
    } = storeData;

    const isHandshakeEnabled = handshaking && handshaking[0] === 'enabled';

    if (!isHandshakeEnabled) return;

    const token = searchParams.get('token') || sessionStorage.getItem('handshakeToken');
    const validatedToken = validateToken(token);

    let updatedHandshakeData: HandshakeDataContextType = defaultHandshakeData;

    if (validatedToken) {
      updatedHandshakeData = {
        ...defaultHandshakeData,
        userData: {
          firstName: validatedToken.meta?.fn || '',
          lastName: validatedToken.meta?.ln || '',
          email: validatedToken.meta?.em || '',
        },
        isAuthorised: true,
        expireAt: validatedToken.exp * 1000,
        expireInSeconds: validatedToken.exp - validatedToken.iat,
        isHandshakeEnabled,
      };

      const returnUrl = searchParams.get('return_url') || sessionStorage.getItem('returnUrl');

      if (returnUrl === 'string' && returnUrl.startsWith('http')) {
        sessionStorage.setItem('returnUrl', returnUrl);

        updatedHandshakeData = {
          ...updatedHandshakeData,
          returnUrl,
        };
      }

      searchParams.delete('token');
      searchParams.delete('return_url');

      setSearchParams(searchParams);
    } else {
      sessionStorage.removeItem('handshakingToken');
      navigate('/unauthorised');
    }

    setHandshakeData(updatedHandshakeData);
  }, [storeData]);
  // TODO: this hook should be dependent on searchParam values

  return <HandshakeDataContext.Provider value={handshakeData}>{children}</HandshakeDataContext.Provider>;
};
