import { FC } from 'react';

import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface SearchBarProps {
  handleSearchTermChange: (searchTerm: string) => void;
}
export const SearchBar: FC<SearchBarProps> = ({ handleSearchTermChange }) => {
  const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => handleSearchTermChange(e.target.value);

  return (
    <InputGroup size="lg">
      <InputLeftElement pointerEvents="none" color="white" bg="navbar" rounded="full">
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </InputLeftElement>
      <Input
        type="search"
        placeholder="Search retailers"
        rounded="full"
        shadow="md"
        pl={16}
        fontSize="md"
        onChange={onSearchTermChange}
      />
    </InputGroup>
  );
};
