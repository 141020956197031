/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, FC, useMemo } from 'react';

import { extendTheme, ChakraProvider, Theme, theme } from '@chakra-ui/react';

import { useStoreDataQuery } from '../hooks/useStoreDataQuery';
import { StoreData } from '../types/storeData';

export type StoreDataContextType = StoreData | null;

export const StoreDataContext = createContext<StoreDataContextType>(null);

interface StoreDataContextProviderProps {
  children: React.ReactNode;
}

export const StoreDataContextProvider: FC<StoreDataContextProviderProps> = ({ children }) => {
  const { isLoading, error, data: storeData } = useStoreDataQuery();

  const { primaryColor, secondaryColor, navbarColor, tertiaryColor, footerBackgroundColor, footerTextColor } =
    storeData?.store || {};

  const storeTheme = useMemo(() => {
    if (!primaryColor) {
      return theme;
    }

    return extendTheme({
      colors: {
        progressBar: {
          500: tertiaryColor,
        },
        primary: primaryColor,
        secondary: secondaryColor,
        navbar: navbarColor,
        tertiary: footerBackgroundColor,
        footer: {
          background: footerBackgroundColor,
          text: footerTextColor,
        },
      },
      sizes: {
        container: {
          xxl: '1440px',
        },
      },
      components: {
        Link: {
          variants: {
            'category-bar': {
              textDecoration: 'none',
              _hover: {
                textDecoration: 'none',
              },
            },
          },
        },
      },
    }) as Theme;
  }, [primaryColor, secondaryColor, navbarColor, tertiaryColor, footerBackgroundColor, footerTextColor]);

  if (error) {
    // TODO: redirect to error page

    // eslint-disable-next-line no-console
    console.error(error);
  }

  return (
    <StoreDataContext.Provider value={!isLoading && storeData ? storeData : null}>
      <ChakraProvider theme={storeTheme}>{children}</ChakraProvider>
    </StoreDataContext.Provider>
  );
};
