import axios from 'axios';

import { StoreData } from '../types/storeData';

export const getStoreData = async () => {
  const baseURL = process.env.REACT_APP_MARKETPLACE_URL;
  const storeCode = process.env.REACT_APP_STORE_CODE;
  const storeLang = process.env.REACT_APP_CMS_LANG;
  const region = process.env.REACT_APP_COUNTRY_CODE?.toLowerCase();

  // eslint-disable-next-line no-console
  console.time('getting store');
  const { data } = await axios.get<StoreData>(`${baseURL}/v1/store-data/${storeCode}`, {
    headers: {
      Region: region,
      'Store-Lang': storeLang,
    },
  });
  // eslint-disable-next-line no-console
  console.timeEnd('getting store');

  return data;
};
