import { RouteObject } from 'react-router-dom';

import { Home } from './pages/Home';
import { Unauthorized } from './pages/Unauthorized';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/unauthorised',
    element: <Unauthorized />,
  },
];
