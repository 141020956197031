import { FC } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';

import { HandshakeDataContextProvider } from './contexts/HandshakeDataContext';
import { StoreDataContextProvider } from './contexts/StoreDataContext';

const queryClient = new QueryClient();

export const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <StoreDataContextProvider>
        <HandshakeDataContextProvider>
          <Outlet />
        </HandshakeDataContextProvider>
      </StoreDataContextProvider>
    </QueryClientProvider>
  );
};

export default App;
