import { FC } from 'react';

import { Box, Container, Heading, Link, VStack, SimpleGrid, Center } from '@chakra-ui/react';

import { useStoreData } from '../hooks/useStoreData';

export const Footer: FC = () => {
  const {
    store: { footer, footerCopyrightText, footerAbnText },
  } = useStoreData();

  return (
    <Box bg="navbar" position="fixed" bottom={0} width="100%" pt={9} pb={3}>
      <Container maxW="container.xxl" color="footer.text" px={5}>
        <SimpleGrid columns={{ sm: 1, md: 4 }}>
          {footer.columns.map(col => {
            return (
              <VStack key={col.title} alignItems="flex-start" w="100%">
                <Heading fontSize="lg" my={5} textAlign="left">
                  {col.title}
                </Heading>
                {col.links.map(link => {
                  return (
                    <Link
                      key={link.text}
                      href={link.url}
                      isExternal={link.openInNewWindow}
                      fontSize="sm"
                      textAlign="left"
                    >
                      {link.text}
                    </Link>
                  );
                })}
              </VStack>
            );
          })}
        </SimpleGrid>
        <Center mt={10} fontSize="xs">
          ©{footerCopyrightText}
          {footerAbnText}
        </Center>
      </Container>
    </Box>
  );
};
