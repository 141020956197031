import { FC, ReactNode } from 'react';

import { Spinner } from '@chakra-ui/react';

import { useOptionalStoreData } from '../hooks/useStoreData';
import { Footer } from './Footer';
import { Header } from './Header';

interface PageLayoutProps {
  children: ReactNode;
}

export const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  const storeData = useOptionalStoreData();
  if (!storeData) {
    return (
      <>
        <p>Loading store data...</p>
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </>
    );
  }

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};
