import { isExpired, decodeToken } from 'react-jwt';

export interface HandshakeToken {
  exp: number;
  iat: number;
  meta?: Partial<{
    fn: string;
    ln: string;
    em: string;
  }>;
}

export const validateToken = (token: string | null): HandshakeToken | null => {
  if (!token) return null;

  const isTokenExpired = isExpired(token);

  if (isTokenExpired) {
    return null;
  }

  return decodeToken(token) as HandshakeToken;
};
