import { RefObject, useEffect, useState } from 'react';

export const useDynamicBreaks = (containerRef: RefObject<HTMLElement>) => {
  const [breakIndex, setBreakIndex] = useState(0);

  const handleResize: ResizeObserverCallback = entries => {
    const { target } = entries[0];

    let row = 0;
    let breakIndex = 0;

    const childrenArray = Array.prototype.slice.call(target.children);

    [...childrenArray].forEach((el, index) => {
      // reset category height to be displayed
      el.style.height = 'auto';
      // if element is more to the left that it's previous element it means it wrapped to a new line
      if (!el.previousElementSibling || el.offsetLeft < el.previousElementSibling.offsetLeft) {
        if (row === 1) {
          breakIndex = index;
        }
        row++;
      }
      // hide wrapped category by setting the height to 0
      el.style.height = row !== 1 ? 0 : 'auto';
    });
    setBreakIndex(breakIndex);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [containerRef]);

  return breakIndex;
};
