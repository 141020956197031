import { FC } from 'react';

import { Box, Center } from '@chakra-ui/react';

export const Unauthorized: FC = () => {
  return (
    <Center h="full" px={2} mt={16}>
      <Box>Unauthorised</Box>
    </Center>
  );
};
