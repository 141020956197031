import { useContext } from 'react';

import { StoreDataContext } from '../contexts/StoreDataContext';

export const useStoreData = () => {
  const storeData = useContext(StoreDataContext);

  if (!storeData) {
    throw Error(
      'useStoreData can only be used after data has been loaded. Consider using useOptionalStoreData if you need to handle app data loading state.'
    );
  }

  return storeData;
};

export const useOptionalStoreData = () => useContext(StoreDataContext);
