import { FC } from 'react';

import { Progress, Container, Box, Image, Text } from '@chakra-ui/react';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Countdown, { CountdownRenderProps } from 'react-countdown';

import { useHandshakeData } from '../hooks/useHandshakeData';
import { useStoreData } from '../hooks/useStoreData';

export const Header: FC = () => {
  const storeData = useStoreData();
  const { isAuthorised, isCountdownEnabled, isHandshakeEnabled, expireAt, expireInSeconds } = useHandshakeData();

  const renderer = (rendererProps: CountdownRenderProps) => {
    const {
      total,
      formatted: { minutes, seconds },
    } = rendererProps;

    const totalSec = total / 1000;
    const ratio = expireInSeconds ? ((expireInSeconds - totalSec) / expireInSeconds) * 100 : 0;

    return (
      <>
        <Text>
          Your session will end in <FontAwesomeIcon icon={faClock} />
          <Box as="span" color="tertiary" display="inline-block" w={12} ml={1} fontWeight="medium">
            {minutes}:{seconds}
          </Box>{' '}
          minutes
        </Text>
        <Progress
          value={ratio}
          colorScheme="progressBar"
          position="absolute"
          bottom={0}
          left={0}
          h={1.5}
          w="full"
          sx={{
            '& div[role=progressbar]': {
              transition: 'width 1s linear',
            },
          }}
        />
        {/* <SessionExpiryModal isShown={completed} /> */}
      </>
    );
  };

  const logoUrl = storeData.store.companyLogo;

  return (
    <Box role="banner" bg="navbar" py={4}>
      <Container maxW="container.xxl" display="flex" justifyContent="space-between" alignItems="center" px={5}>
        {logoUrl != '' ? <Image width={28} objectFit="contain" src={logoUrl} alt="Company Logo" /> : null}
        <Box>
          {isHandshakeEnabled && isAuthorised && expireAt && isCountdownEnabled && (
            <Countdown date={expireAt} renderer={renderer} />
          )}
        </Box>
      </Container>
    </Box>
  );
};
