import { FC } from 'react';

import { Container } from '@chakra-ui/react';

import { CategoryBar } from '../components/CategoryBar';
import { PageLayout } from '../components/PageLayout';
import { SearchBar } from '../components/SearchBar';

export const Home: FC = () => {
  // This component is being edited elsewhere
  /* istanbul ignore next */
  const handleSearchTermChange = (searchTerm: string) => {
    // eslint-disable-next-line no-console
    console.log('searchTerm: ', searchTerm);
  };

  return (
    <PageLayout>
      <Container maxW="container.xxl" px={5} pt={16}>
        <SearchBar handleSearchTermChange={handleSearchTermChange} />
        <CategoryBar />
      </Container>
    </PageLayout>
  );
};
